import React from 'react'

export const Form = () => {
    return (
        <>
        <div className="bg-gray-200 border border-red-500 h-full w-full m-auto ">
            <div className="bg-gray-200  h-full w-1/2 m-auto ">
                <form >
                    <div className="h-full w-full">
                        <p className="text-xl pl-9 pt-5 pb-1">Name</p>
                        <input type="text" className="border border-red-500 ml-9 w-11/12" placeholder="Enter name" required></input><br />

                        <p className="text-xl pl-9 pt-5 pb-1">Email</p>
                        <input type="text" className="border border-red-500 ml-9 w-11/12" placeholder="Email" required></input><br />

                        <p className="text-xl pl-9 pt-5 pb-1">Phone no. </p>
                        <input type="text" className="border border-red-500 ml-9 w-11/12" placeholder="Phone number" required></input><br />
                        
                        <p className="text-xl pl-9 pt-5 pb-1">Country</p>
                        <input type="text" className="border border-red-500 ml-9 w-11/12" placeholder="country name" required></input><br />
                        
                        <p className="text-xl pl-9 pt-5 pb-1">Service</p>
                        <input type="text" className="border border-red-500 ml-9 w-11/12" placeholder="In Which tech you work " required></input><br />
                        
                        <p className="text-xl pl-9 pt-5 pb-1">How can We Help You</p>
                        <textarea  className="border border-red-500 ml-9 w-11/12 h-40">

                            </textarea><br />
                            <button type="submit" className="mx- float-none lg:mx-12 hover:underline bg-gray-100 text-gray-800 font-bold rounded-full my-6 py-2 px-6 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                            Submit
          </button>
                    </div>

                </form>
            </div>
            </div>
        </>
    )
}
