import './App.css';
import { Header } from './myCom/Header.js';
import { Footer } from './myCom/Footer.js';
import { Main } from './myCom/Main.js';
import { AU } from './myCom/AU.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
function App() {
  return (
    <>
      <Router>
        <Header />

        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route exact path="/AU">
            <AU />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
export default App;
