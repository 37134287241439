import React from 'react'
import './css/header.css';
import logo from './image/2logoiria.jpg';
import {
    Link
} from "react-router-dom";
export const Header = () => {
    return (
        <div>
            <header className="md:px-10 px-5 bg-white flex flex-wrap items-center md:py-1 py-1  ">

                <div className="flex-1 flex justify-between items-center">
                    
                        <Link to="/" className="cursor-pointer">
                            <div className="w-24 h-24">
                                <img src={logo} alt="logo"/>
                            </div>
                        </Link>
                    
                </div>
                <label for="menu-toggle" className="cursor-pointer md:hidden block"><svg class="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path></svg></label>
                <input className="hidden" type="checkbox" id="menu-toggle" />


                <div className="hidden md:flex md:items-center md:w-auto w-full" id="menu">
                    <nav>
                        <ul className="md: text-base text-gray-700 pt-4 md:pt-0">
                            <li><Link className="md:p-0 py-3 px-0 text-xl block border-b-2 border-transparent hover:border-indigo-400" to="/">Home</Link></li>
                            <li><Link className="md:p-0 py-3 px-0 text-xl block border-b-2 border-transparent hover:border-indigo-400" to="/AU">About Us</Link></li>
                        </ul>
                    </nav>

                </div>

            </header>
        </div>
    )
}
