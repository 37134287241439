import React from 'react';
import './css/Main.css';
import { Link } from 'react-router-dom';
import image1 from './image/1_2x2.jpg';
import image2 from './image/2_2x2.jpg';
import image3 from './image/3_2x2.jpg';
import image4 from './image/5_2x2.jpg';

export const AU = () => {
  return (
    <>
      <div className="Banner opacity-90 ">
        <div className="w-11/12 h-full au-Banner-tag md:w-4/5 ">
          <div className="h-full pt-40 text-center md:w-4/5 card">
            <label className="font-serif text-4xl antialiased subpixel-antialiased font-medium font-bold tracking-wide text-black md:text-6xl hover:text-gray-500">
              Our goal is to find the best possible solutions in the immobilize
              society
            </label>
            <br />
            <Link to="#below">
              <button
                className="px-3 py-1 font-serif text-xl text-red-300 transform bg-gray-800 border border-gray-200 rounded-lg hover:scale-110 motion-reduce:transform-none my-7"
                onClick="yes"
              >
                Show More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Link name="below"></Link>
      <div className="w-full my-40 md:flex">
        <div className="card md:w-1/2 h-96">
          <div class="container align-center my-6">
            <div className="">
              <img src={image1} className="" alt="" />
            </div>
            <div className="my-4 text-4xl text-center hover:underline hover:text-gray-500">
              Research
            </div>
            <div className="px-6 my-4 text-xl text-center sans-serif">
              With Iria research,you can align your technology strategy to skill
              strategy
            </div>
          </div>
        </div>
        <div className="card md:w-1/2 h-96">
          <div class="container align-center my-6">
            <img src={image2} className="" alt="" />
            <div className="my-4 text-4xl text-center hover:underline hover:text-gray-500">
              Development
            </div>
            <div className="px-6 my-4 text-xl text-center sans-serif">
              You can tap into expertise from here and develop skills quickly
            </div>
          </div>
        </div>
        <div className=" card md:w-1/2 h-96">
          <div class="container align-center my-6">
            <img src={image3} className="" alt="" />
            <div className="my-4 text-4xl text-center hover:underline hover:text-gray-500">
              Integration
            </div>
            <div className="px-6 my-4 text-xl text-center sans-serif">
              We work on different and latest technology and have grreat work
              culture
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full md:founder">
        <div className="w-11/12 card founder-1 md:flex md:w-3/5 ">
          {/* <div className="w-5/6 card md:w-1/3">
                        <img src={image4} className="my-9" alt="" />
                        <div className="my-6 text-xl text-center" >Our team</div>
                        <div className="px-10 text-center "> </div>
                    </div> */}
          <div className="w-full card md:w-2/3">
            <h2 className="py-4 mx-3 my-4 text-3xl text-center">
              Greetings to all clients out there
            </h2>
            <p className="px-2 mx-3 my-4 text-xl text-center ">
              We welcome you to the platform where we consistently strive to
              offer the best of the Business
            </p>
            <p className="px-2 mx-3 my-4 text-xl text-center">
              Our goal is to find the best possible solutions in the immobilize
              society. If we succeed with this,it'll give anyone who wants to
              become a software developer a realistic shot at succeeding.
              Regardless of where they live.
            </p>
            <p className="px-3 my-4 text-3xl text-center">
              {' '}
              Thank you for choosing and supporting us !
            </p>
          </div>
        </div>
      </div>

      <div className="items-center mt-32 bg-black h-11/12">
        <div className="my-16 au-Banner-tag md:w-4/5">
          <div className="py-16 pt-16 text-center ">
            <div>
              <p className="pl-16 text-yellow-500 pr-32my-4 p-14 hover:text-yellow-200">
                How would it feel to live in a world with no creativity? Boring
                right? So we are here to keep creativity alive in the world. Top
                developers and designers from our Iria Research community have
                joined forces to build a team of exceptional talent, ready to
                collaborate and cater to the development needs of any brand and
                company.
                <br />
                <br />
                Ideas excite and inspire us, We are always open to ideas. So if
                you have any business ideas or there’s anything that’s holding
                you back from starting something new then get in touch today for
                a free consultation or a not-so-great' coffee in our studio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
