import React from 'react'
import './css/Main.css';
export const Card2 = () => {
    return (
        <>
            <div>
                <div className=" bg-black text-white w-full height-full border border-blue-500">
                    <h1 className="text-5xl text-yellow-500 font-semibold px-10 pt-8"> How we Work</h1>
                    <section>
                        <p className="text-xl  font-light px-10 pt-3">
                            We’ve worked with a broad range of individuals and businesses since. All of our clients benefit from our world-class service and unparalleled creative methods. These are just some of the clients whose brands and businesses we have transformed. Take a look below and get in touch to see how we can help you today.
                    </p>
                    </section>
                    <div className="mt-10">

                        <label className="text-5xl text-yellow-500 font-bold pl-10 pt-8">OUR WORK.</label><label className="pt-8 text-5xl text-white font-bold"> Creative Original</label>
                        <p className="text-xl  font-light px-10 pt-3">
                            From hands-on creative work to developing new-age digital products, we want to ensure that all of our clients find exactly what they’re looking for. A firm belief in sustainable and functional design defines the kind of services offered here at Cultor Studio. Below have a look at some of the projects that we have worked on.
                            If you have any additional questions, please do not hesitate to get in touch.
               </p>
                        <a className="text-xl  font-light px-10 pt-7"> Ceemulti</a>
                    </div>
                </div>
            </div>
        </>
    )
}
